<template>
  <div
    class="min-h-58 d-flex w-100 align-items-center justify-content-center"
    v-if="!hasSnapshot && !loading"
  >
    <div class="text-center">
      <b-icon icon="calculator" class="h1" />
      <p class="my-3">Ainda não há informações dos indicadores financeiros.</p>
      <b-button @click="handleSyncronize()" v-if="is_provider && enabled">
        <b-icon icon="arrow-down-up" class="mr-2" />
        <span>Sincronizar agora</span>
      </b-button>
    </div>
  </div>
  <div v-else>
    <skeleton grid="1" gap="10" :rows="20" v-if="!activity" height="30px" />
    <div v-if="activity">
      <div class="d-md-flex align-items-center justify-content-between mb-2">
        <div
          class="d-flex mb-3 mb-md-0 align-items-center justify-content-cebter"
        >
          <small class="mr-2">
            Ultima atualização {{ accounting_entry.updated_at | dateFull }}
          </small>
          <unicon
            v-if="is_provider && enabled"
            name="redo"
            @click="handleSyncronize()"
            class="pointer small"
            style="font-size: 12px"
            height="16"
            width="16"
          />
        </div>

        <div class="d-flex align-items-center justify-content-end mb-2">
          <b-button
            :variant="visualization === 'consolidated' ? 'info' : 'light'"
            size="sm"
            class="mb-0 mr-2"
            @click="handleChangeVisualization('consolidated')"
          >
            Consolidados
          </b-button>
          <b-button
            :variant="visualization === 'individual' ? 'info' : 'light'"
            size="sm"
            class="mb-0"
            @click="handleChangeVisualization('individual')"
          >
            Individuais
          </b-button>
        </div>
      </div>
      <div class="row m-0 mt-4">
        <section v-if="visualization === 'individual'" class="w-100">
          <div class="d-md-flex justify-content-between w-100">
            <h5>Aspectos individuais ultimos 4 trimestres</h5>
            <div class="d-flex justify-content-center gap-1">
              <b-button
                size="sm"
                v-for="(section, qIndex) in activity.quarters"
                :key="qIndex"
                class="mb-3"
                :variant="selectedIndex === qIndex ? 'primary' : 'light'"
                @click="handleSetQuarterIndice(qIndex)"
              >
                {{ getTitle(section) }}
              </b-button>
            </div>
          </div>
          <section class="col-md-12 p-0">
            <div v-for="(qrt, qIndex) in quarter" :key="qIndex" class="mb-3">
              <span class="mb-2 d-block">{{ qrt.name }}</span>
              <cards-list
                grid="4"
                responsive="2"
                gap="10"
                class="cards-balance"
              >
                <b-card
                  no-body
                  v-for="(entrie, eIndex) in qrt.entries"
                  :key="eIndex"
                  class="
                    bordered
                    d-flex
                    align-items-center
                    justify-content-center
                    p-2
                  "
                >
                  <Medal :ranking="entrie.ranking" class="mb-2" />
                  <small>{{ entrie.name }}</small>
                  <strong class="d-block">{{ getValue(entrie) }}</strong>
                </b-card>
              </cards-list>
            </div>
          </section>
        </section>
        <section class="col-md-12 p-0" v-else>
          <div class="d-md-flex align-items-center justify-content-between">
            <h5>Aspectos Consolidados</h5>
            <div class="d-flex align-items-center justify-content-end mb-2">
              <b-button
                :variant="period === 'quarters' ? 'light' : 'primary'"
                size="sm"
                class="mb-0 mr-2"
                @click="handleChangePeriod('years')"
              >
                Anual
              </b-button>
              <b-button
                :variant="period === 'years' ? 'light' : 'primary'"
                size="sm"
                class="mb-0"
                @click="handleChangePeriod('quarters')"
              >
                Trimestral
              </b-button>
            </div>
          </div>
          <div
            v-for="(section, qIndex) in consolidated"
            :key="qIndex"
            class="mb-3"
          >
            <span class="mb-2 d-block">{{ section.name }}</span>
            <cards-list grid="3" gap="10" class="cards-balance">
              <b-card
                v-for="(entrie, eIndex) in section[period]"
                :key="eIndex"
                class="
                  bordered
                  d-flex
                  justify-content-center
                "
              >
                <strong class="mb-3">{{ getTitle(entrie) }} </strong>
                <section
                  v-for="(data, ii) in entrie.entries"
                  :key="ii"
                  class="d-flex align-items-center mb-4"
                >
                  <Medal :ranking="data.ranking" class="mr-3" />
                  <span class="mr-2">{{ data.name }}:</span>
                  <strong>{{ getValue(data) }}</strong>
                </section>
              </b-card>
            </cards-list>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Medal from "./Medal";

export default {
  components: {
    Medal,
  },

  data() {
    return {
      visualization: "consolidated",
      selectedIndex: 0,
      activity: {},
      rowUpdating: null,
      loading: false,
      id: "",
      provider_id: "",
      service_id: "",
      quarter: [],
      consolidated: [],
      columnIndex: null,
      rowIndex: null,
      hasSnapshot: false,
      enabled: false,
      period: "years",
      accounting_entry: {
        years: [],
      },
      periods: [],
      homologation: "",
      fields: [],
    };
  },
  computed: {
    ...mapGetters(["is_preview", "user", "preview_personify", "is_provider"]),
  },
  methods: {
    ...mapActions(["get_snapshot", "set_snapshot", "show_loading"]),

    getValue({ type_view, value }) {
      if (typeof value === undefined) {
        return 0;
      }

      if (type_view === "money") {
        const formatter = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BLR",
        });
        return formatter.format(value).replace("BLR", "R$");
      }

      if (type_view === "value") {
        return `${new Intl.NumberFormat("pt-BR").format(value.toFixed(2))}`;
      }

      return `${new Intl.NumberFormat("pt-BR").format(
        (value * 100).toFixed(2)
      )}%`;
    },

    handleChangeVisualization(visualization) {
      this.visualization = visualization;
    },

    handleChangePeriod(period) {
      this.period = period;
    },

    handleSetQuarterIndice(index) {
      this.selectedIndex = index;
      this.quarter = this.quarters[index].sections;
    },

    getTitle(entrie) {
      return entrie.quarter
        ? `${entrie.quarter[0]}T ${entrie.quarter[1]}`
        : entrie.year;
    },

    getSnapshot() {
      this.loading = true;
      this.show_loading({ loading: true });
      this.get_snapshot({
        type: "income",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.enabled = response.enabled;
        if (!response?.created_at) {
          this.hasSnapshot = false;
        } else {
          this.hasSnapshot = true;
          this.activity = response.data;
          this.consolidated = response.data.consolidated;
          this.quarters = response.data.quarters;

          const startSection = this.quarters[0];

          this.quarter = startSection?.sections;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },

    handleSyncronize() {
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "income",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id || this.$route.query.provider_id;

    this.getSnapshot();
  },
};
</script>
<style lang="scss" scoped>
.input-loading {
  &.table {
    svg {
      right: auto;
      left: 5px;
      top: 4px;
      display: block !important;
    }
  }
}
tr {
  font-size: 13px;
  &.disabled {
    background: #dae4f5;
    td {
      color: #212529;
    }
  }
}
.card-entrie {
  padding: 0 2em;
}
.table-field {
  border-color: transparent;
  height: 30px !important;
  &.disabled-input {
    margin-right: 13px;
    border-color: transparent !important;
    .calc {
      position: relative;
      top: auto;
      right: auto;
      opacity: 0.7;
      display: flex !important;
      animation: none !important;
    }
    .disc {
      display: flex !important;
      right: auto;
      left: 10px;
      top: 10px;
    }
  }
  &.negative {
    color: $danger !important;
  }
  &.updating {
    text-shadow: 0 0 5px rgba(223, 223, 223, 0.5);
  }
}
</style>
